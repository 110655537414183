import Api from "./index";
import { getAccessToken } from "../authTokens";
import sendErrorToSentry from "../sentry";

import {
  dataApiAuth,
  backendRequestForDownload,
  IDataGetDownloadLink,
  ITrackInfo,
} from "./types";
import {
  IGetInitialOptionsChainElement,
  IGetOptionsForNextChainElement,
  IGetOptionsOnScroll,
  ISearchOptionsForNextChainElement,
} from "@globalTypes/simpleSearch";
import { MAX_CHAIN_ELEMENT } from "@helpers/searchSimple";

const authApiUrl = process.env.REACT_APP_AUTH_ENDPOINT;
const searchApiUrl = process.env.REACT_APP_SIMPLE_ADDRESS;
const searchSimpleUrl = process.env.REACT_APP_SIMPLE_CAMERAS;

function buildParams<T extends Record<string, string | number>>(
  params: T
): string {
  return Object.keys(params).reduce(function(previous, key) {
    return previous + `${key}=${params[key]}&`;
  }, "?");
}

export const ApiAgent = (() => {
  const api = Api.create();
  try {
    const accessToken = getAccessToken();
    if (accessToken) {
      api.setToken(accessToken);
    }
  } catch (e) {
    sendErrorToSentry(`Error broken access token ${e}`, {
      place: "src/helpers/api/apiMethods.ts",
    });
  }
  return api;
})();

export const setToken = () => {
  try {
    const accessToken: string = getAccessToken();
    ApiAgent.setToken(accessToken);
  } catch (e) {
    sendErrorToSentry(`Error broken access token ${e}`, {
      place: "src/helpers/api/apiMethods.ts",
    });
  }
};

const apiMethods: any = {};

/**
 * Получить содержание группы с указанным id
 */
apiMethods.getGetGroup = (id: string | number | null = "") => {
  if (id === 0 || id === undefined) id = "";
  return ApiAgent.get(`get-group/${id}`);
};

apiMethods.getCamerasLimitedInfo = (data: object) => {
  return ApiAgent.post(`limited-info`, data);
};

apiMethods.checkToken = () => {
  return ApiAgent.post(`${authApiUrl}token/check`);
};

apiMethods.getTokenInfo = () => {
  return ApiAgent.get(`${authApiUrl}token/info`);
};

apiMethods.auth = (data: dataApiAuth) => {
  return ApiAgent.post(`${authApiUrl}auth/password`, data);
};

apiMethods.getCameraEvents = (data: any) => {
  return ApiAgent.get(`event`, { params: data });
};

/**
 * Получить содержание группы с указанным id
 */
apiMethods.search = (term: string = "") => {
  return ApiAgent.get(`search`, { params: { term } });
};

/* Загрузка видео из архива
 * {
 *  "CAMERA_ID": 97,
 *  "START_TIME": "24.01.2019 14:05",
 *  "STOP_TIME": "24.01.2019 14:15"
 * }
 */

apiMethods.orderVideoForDownload = (data: backendRequestForDownload) => {
  const { cameraId, startTimeAsString, stopTimeAsString } = data;
  return ApiAgent.post(`order-whole`, {
    CAMERA_ID: cameraId,
    START_TIME: startTimeAsString,
    STOP_TIME: stopTimeAsString,
  });
};

/* Получение ссылки на загрузку видео
 *
 * {
 *  "uuid": 21221,
 * }
 */
apiMethods.getDownloadLink = (data: IDataGetDownloadLink) => {
  const { uuid } = data;
  return ApiAgent.post(`get-file`, {
    uuid,
  });
};

apiMethods.getFavouriteCameras = () => {
  return ApiAgent.get(`person-group/favorite`);
};

/**
 * Добавить избранную группу
 */
apiMethods.addFavouriteGroup = (data: any) => {
  const { camerasIds } = data;
  return ApiAgent.post(`person-group/favorite`, {
    name: "Избранное",
    cameraIds: camerasIds,
  });
};

/**
 * Добавить избранную группу
 */
apiMethods.updateFavouriteGroup = (data: any) => {
  const { camerasIds, groupId } = data;
  return ApiAgent.put(`person-group/${groupId}`, {
    cameraIds: camerasIds,
  });
};

/**
 * Вызвать звонок на телефон пользователя
 */
apiMethods.askForMobileCode = (data: any) => {
  const { phone, uniqueDeviceId } = data;
  return ApiAgent.post(`${authApiUrl}mobile/auth/send-sms`, {
    phone,
    uniqueDeviceId,
  });
};

/**
 * Отправить код для авторизации
 */
apiMethods.sendMobileCode = (data: any) => {
  const { confirmCode, phone } = data;
  return ApiAgent.post(`${authApiUrl}mobile/auth/confirm`, {
    confirmCode,
    phone,
  });
};

/**
 * Отправить код для авторизации
 */
apiMethods.sendMobileAuthToken = (data: any) => {
  const { authId, uniqueDeviceId, userId } = data;
  return ApiAgent.post(`${authApiUrl}mobile/auth/get-token`, {
    authId,
    uniqueDeviceId,
    userId,
  });
};

/**
 * Получить изначальные опции для Select
 */
apiMethods.getInitialOptionsChainElement = ({
  perPage,
  page,
}: IGetInitialOptionsChainElement) =>
  ApiAgent.get(
    searchApiUrl +
      "search" +
      buildParams({ maxChain: MAX_CHAIN_ELEMENT, "per-page": perPage, page })
  );

/**
 * Искать опции для Select при выборе следующего шага
 * @param {object} searchOptions
 */
apiMethods.searchOptionsForNextChainElement = ({
  value,
  id,
  string,
  perPage,
}: ISearchOptionsForNextChainElement) => {
  //Два разных типа поиска
  const uriWithParams = `${searchApiUrl}search?q=${string}&maxChain=porch&per-page=${perPage}`;

  const lastObj =
    value !== undefined && id !== undefined
      ? `&lastObject[name]=${value}&lastObject[id]=${id}`
      : "";

  const encodedUri = encodeURI(uriWithParams + lastObj);
  return ApiAgent.get(encodedUri);
};

/**
 * Получить  опции для Select при выборе следующего шага
 * @param {object} searchOptions
 */
apiMethods.getOptionsForNextChainElement = ({
  id,
  value,
  perPage,
}: IGetOptionsForNextChainElement) => {
  const uriWithParams =
    searchApiUrl +
    `search?maxChain=${MAX_CHAIN_ELEMENT}&per-page=${perPage}` +
    `&lastObject[name]=${value}&lastObject[id]=${id}`;
  const encodedUri = encodeURI(uriWithParams);
  return ApiAgent.get(encodedUri);
};

/**
 * Получить  опции для Select при выборе следующего шага
 * @param {object} searchOptions
 */
apiMethods.getOptionsOnScroll = ({
  id,
  value,
  perPage,
  page,
}: IGetOptionsOnScroll) => {
  const uriWithParams =
    searchApiUrl +
    `search?maxChain=${MAX_CHAIN_ELEMENT}&per-page=${perPage}&page=${page}` +
    `&lastObject[name]=${value}&lastObject[id]=${id}`;
  const encodedUri = encodeURI(uriWithParams);
  return ApiAgent.get(encodedUri);
};

/**
 * Поиска камер по simple address
 * @param {object} searchOptions
 */
apiMethods.searchAddress = ({ type, objectId }: any) => {
  const uriWithParams =
    searchSimpleUrl +
    `cams-with-address-object?object-type=${type}&object-id=${objectId}`;
  return ApiAgent.get(uriWithParams);
};

//  TRACK API METHODS ---------------------------------------------------------

const TRACK_API_PATH = process.env.REACT_APP_TRACK_API_PATH;

apiMethods.addGeotagToTrack = ({ track_id, ...data }: ITrackInfo) => {
  return ApiAgent.put(
    `${TRACK_API_PATH}/track/${track_id}`,
    { ...data },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TRACK_API_TOKEN}`,
      },
    }
  );
};

// ----------------------------------------------------------------------------

export default apiMethods;
