import sendErrorToSentry from "../sentry";
import ErrorLog from "../error";
import _ from "lodash";

/**
 * Валидация объекта что у него есть свойства
 * ID, NAME, HLS, SNAPSHOT и что у свойства SNAPSHOT есть свойства HD и LOsSY
 * @param {object} cameraObject
 */
export const validateCameraObject = cameraObject => {
  const { NAME, ID, MEDIA, REALTIME_WS, LINK_TO_ADMIN, UUID } = cameraObject;

  if (!ID || !UUID || !NAME || !MEDIA.HLS.LIVE || !MEDIA.SNAPSHOT.LIVE) {
    ErrorLog.sendError(
      null,
      `Ошибка при валидации объекта validateCameraObject  ID = ${ID} SNAPSHOT=${JSON.stringify(
        MEDIA.SNAPSHOT.LIVE
      )} typeof Snapshot ${typeof MEDIA.SNAPSHOT.LIVE === "object"}`,
      `MEDIA.SNAPSHOT.LIVE.hasOwnProperty('MAIN') = ${MEDIA.SNAPSHOT.LIVE.hasOwnProperty(
        "MAIN"
      )}, MEDIA.SNAPSHOT.LIVE.hasOwnProperty("LOSSY") =  ${MEDIA.SNAPSHOT.LIVE.hasOwnProperty(
        "LOSSY"
      )}`
    );

    return { VALID: false };
  }

  const VALID = true;
  const HLS = MEDIA.HLS.LIVE.MAIN;
  const REALTIME = REALTIME_WS;
  const HDSNAPSHOT = MEDIA.SNAPSHOT.LIVE.MAIN;
  const LOSSYSNAPSHOT = MEDIA.SNAPSHOT.LIVE.LOSSY;
  const REALTIME_CAROUSEL = REALTIME.sub ? REALTIME.sub : REALTIME.main;

  return {
    NAME,
    HLS,
    REALTIME,
    REALTIME_CAROUSEL,
    HDSNAPSHOT,
    LOSSYSNAPSHOT,
    VALID,
    ID,
    UUID,
    LINK_TO_ADMIN,
  };
};

/*
 * Для сортировки имен (сортировки по имени камеры)
 */
export const utilsFunction = {
  sortFuncName: (a, b) => {
    return a.localeCompare(b);
  },
};

/**
 * Типы возможных сортировок
 */
export const defaultSortSettings = {
  default: {
    id: 0,
    name: "default",
    text: "",
    sortFunc: (camsList, type) => camsList,
    display: false,
  },
};

export const getSortSettings = () => defaultSortSettings;

export const getFunctionByName = functionName => {
  if (defaultSortSettings[functionName]) {
    return defaultSortSettings[functionName].sortFunc;
  }
  return defaultSortSettings["default"].sortFunc;
};
